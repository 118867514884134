
import React, {useEffect, useState} from 'react';
import ClickOutside from './ClickOutside';
import { getWholeDay } from '../graphCalculator';

function Planner(props) {
    let {
        preDays,
        postDays,
        activities,
        isDirty,
        readOnly,
        currentChronic,
    } = props;

    var today = new Date();
    var todayLabel = getWholeDay(today);

    // Contains the activities to plot
    const [editVisible, setEditVisible] = useState(false);
    const [editProperties, setEditProperties] = useState({ top: 0, left: 0 });
    const [currentPlannerDay, setCurrentPlannerDay] = useState(null);


    const [planStartingLoad, setPlanStartingLoad] = useState(currentChronic);
    const [planTargetLoad, setPlanTargetLoad] = useState(Math.round(currentChronic));
    let defaultTargetDate = new Date();
    defaultTargetDate.setDate(defaultTargetDate.getDate() + 28);
    const [planTargetDate, setPlanTargetDate] = useState(defaultTargetDate);
    const [startPlanning, setStartPlanning] = useState(false);

    const [planPracticeDaysSun, setPlanPracticeDaysSun] = useState(0);
    const [planPracticeDaysMon, setPlanPracticeDaysMon] = useState(0);
    const [planPracticeDaysTue, setPlanPracticeDaysTue] = useState(0);
    const [planPracticeDaysWed, setPlanPracticeDaysWed] = useState(0);
    const [planPracticeDaysThu, setPlanPracticeDaysThu] = useState(0);
    const [planPracticeDaysFri, setPlanPracticeDaysFri] = useState(0);
    const [planPracticeDaysSat, setPlanPracticeDaysSat] = useState(0);

    const [planCompDaysSun, setPlanCompDaysSun] = useState(0);
    const [planCompDaysMon, setPlanCompDaysMon] = useState(0);
    const [planCompDaysTue, setPlanCompDaysTue] = useState(0);
    const [planCompDaysWed, setPlanCompDaysWed] = useState(0);
    const [planCompDaysThu, setPlanCompDaysThu] = useState(0);
    const [planCompDaysFri, setPlanCompDaysFri] = useState(0);
    const [planCompDaysSat, setPlanCompDaysSat] = useState(0);

    // If it is mobile view we only want to show 7 days
    // Days before today to show on the calendar
    preDays = props.isMobile ? 7 : preDays;

    // Days after today to show on the calendar
    postDays = props.isMobile ? 7 : postDays;

    const showEditable = (cell, day) => {
        var rect = cell.getBoundingClientRect();
        setEditProperties({ top: rect.top, left: rect.left })
        setEditVisible(true);
        setCurrentPlannerDay(day);
    }

    // We need to plot the pre days and post days
    // We also need the chronic duration before the start of the graph
    let days = preDays + postDays;
    let todayIndex = 1;

    const onPlanStartLoadChange = (val) => {
        setPlanStartingLoad(val);
    }

    const onPlanTargetLoadChange = (val) => {
        setPlanTargetLoad(val);
    }

    const onPlanTargetDateChange = (val) => {
        setPlanTargetDate(val);
    }

    const onPlanDaysOfWeekChange = (val, day, type) => {
        val = parseInt(val);
        if (type === 'practice') {
            switch (day) {
                case 'Sun':
                    setPlanPracticeDaysSun(val);
                    break;
                case 'Mon':
                    setPlanPracticeDaysMon(val);
                    break;
                case 'Tue':
                    setPlanPracticeDaysTue(val);
                    break;
                case 'Wed':
                    setPlanPracticeDaysWed(val);
                    break;
                case 'Thu':
                    setPlanPracticeDaysThu(val);
                    break;
                case 'Fri':
                    setPlanPracticeDaysFri(val);
                    break;
                case 'Sat':
                    setPlanPracticeDaysSat(val);
                    break;
                default:
                    console.error('Invalid day');
            }
        }
        else if (type === 'competitive') {
            switch (day) {
                case 'Sun':
                    setPlanCompDaysSun(val);
                    break;
                case 'Mon':
                    setPlanCompDaysMon(val);
                    break;
                case 'Tue':
                    setPlanCompDaysTue(val);
                    break;
                case 'Wed':
                    setPlanCompDaysWed(val);
                    break;
                case 'Thu':
                    setPlanCompDaysThu(val);
                    break;
                case 'Fri':
                    setPlanCompDaysFri(val);
                    break;
                case 'Sat':
                    setPlanCompDaysSat(val);
                    break;
                default:
                    console.error('Invalid day');
            }
        }
    }

    const makePlan = () => {
        let starting = parseInt(planStartingLoad);
        let target = planTargetLoad;
        let targetDate = new Date(planTargetDate);

        let weeklyPlanned = planPracticeDaysSun + planPracticeDaysMon + planPracticeDaysTue + planPracticeDaysWed + planPracticeDaysThu + planPracticeDaysFri + planPracticeDaysSat;
        let weeklyComp = planCompDaysSun + planCompDaysMon + planCompDaysTue + planCompDaysWed + planCompDaysThu + planCompDaysFri + planCompDaysSat;
        let totalWeekly = weeklyPlanned + weeklyComp;

        let practiceDays = {};
        let competitiveDays = {};
        if (planPracticeDaysSun > 0) practiceDays['Sun'] = Math.round(planPracticeDaysSun * 100 / totalWeekly) / 100;
        if (planPracticeDaysMon > 0) practiceDays['Mon'] = Math.round(planPracticeDaysMon * 100 / totalWeekly) / 100;
        if (planPracticeDaysTue > 0) practiceDays['Tue'] = Math.round(planPracticeDaysTue * 100 / totalWeekly) / 100;
        if (planPracticeDaysWed > 0) practiceDays['Wed'] = Math.round(planPracticeDaysWed * 100 / totalWeekly) / 100;
        if (planPracticeDaysThu > 0) practiceDays['Thu'] = Math.round(planPracticeDaysThu * 100 / totalWeekly) / 100;
        if (planPracticeDaysFri > 0) practiceDays['Fri'] = Math.round(planPracticeDaysFri * 100 / totalWeekly) / 100;
        if (planPracticeDaysSat > 0) practiceDays['Sat'] = Math.round(planPracticeDaysSat * 100 / totalWeekly) / 100;

        if (planCompDaysSun > 0) competitiveDays['Sun'] = Math.round(planCompDaysSun * 100 / totalWeekly) / 100;
        if (planCompDaysMon > 0) competitiveDays['Mon'] = Math.round(planCompDaysMon * 100 / totalWeekly) / 100;
        if (planCompDaysTue > 0) competitiveDays['Tue'] = Math.round(planCompDaysTue * 100 / totalWeekly) / 100;
        if (planCompDaysWed > 0) competitiveDays['Wed'] = Math.round(planCompDaysWed * 100 / totalWeekly) / 100;
        if (planCompDaysThu > 0) competitiveDays['Thu'] = Math.round(planCompDaysThu * 100 / totalWeekly) / 100;
        if (planCompDaysFri > 0) competitiveDays['Fri'] = Math.round(planCompDaysFri * 100 / totalWeekly) / 100;
        if (planCompDaysSat > 0) competitiveDays['Sat'] = Math.round(planCompDaysSat * 100 / totalWeekly) / 100;

        let increaseNeeded = target - starting;
        let daysUntilTarget = (targetDate - today) / (1000 * 60 * 60 * 24);
        let increaseNeededPerDay = increaseNeeded / (daysUntilTarget);

        let plannerDay = new Date();
        plannerDay.setDate(today.getDate());
        let dayCounter = 0;

        let trainingDays = [];
        while (plannerDay < targetDate) {
            let dayOfWeek = plannerDay.toLocaleDateString('en-us', { weekday: 'short' });
            let plannerDayLabel = getWholeDay(plannerDay);

            if (practiceDays.hasOwnProperty(dayOfWeek)) {
                let scaledValue = Math.round((starting + (increaseNeededPerDay * dayCounter)) * practiceDays[dayOfWeek] * 10) / 10;
                trainingDays.push({ time: plannerDayLabel, value: scaledValue, type: 'practice' });

                // It has practice, so add a 0 value for competitive if there aren't any competitive
                if (!competitiveDays.hasOwnProperty(dayOfWeek)) {
                    trainingDays.push({ time: plannerDayLabel, value: 0, type: 'competitive' });
                }
            }
            else if (competitiveDays.hasOwnProperty(dayOfWeek)) {
                let scaledValue = Math.round((starting + (increaseNeededPerDay * dayCounter)) * competitiveDays[dayOfWeek] * 10) / 10;
                trainingDays.push({ time: plannerDayLabel, value: scaledValue, type: 'competitive' });

                // It has competitive, so add a 0 value for practice if there aren't any practice
                if (!competitiveDays.hasOwnProperty(dayOfWeek)) {
                    trainingDays.push({ time: plannerDayLabel, value: 0, type: 'practice' });
                }
            }
            else {
                // It doesn't have competitive or practice, so add a 0 value for both
                trainingDays.push({ time: plannerDayLabel, value: 0, type: 'competitive' });
                trainingDays.push({ time: plannerDayLabel, value: 0, type: 'practice' });
            }
            dayCounter++;
            plannerDay.setDate(plannerDay.getDate() + 1);
        }

        props.updatePlanned(trainingDays);
    }

    useEffect(() => {
        if (startPlanning) {
            makePlan();
        }
    }, [startPlanning, planStartingLoad, planTargetLoad, planTargetDate, planPracticeDaysSun, planPracticeDaysMon, planPracticeDaysTue, planPracticeDaysWed, planPracticeDaysThu, planPracticeDaysFri, planPracticeDaysSat, planCompDaysSun, planCompDaysMon, planCompDaysTue, planCompDaysWed, planCompDaysThu, planCompDaysFri, planCompDaysSat]);


    var calendar = [];
    var dayCounter = 0;
    var weekCounter = 0;
    calendar[weekCounter] = [];
    var roundDownWeek = true;

    let plannerDay = currentPlannerDay;

    while (todayIndex <= days) {
        var currentDay = new Date();
        currentDay.setDate(today.getDate() - preDays + todayIndex);

        let currentDayLabel = getWholeDay(currentDay);

        var criteria;
        if (todayIndex < (preDays)) {
            // This is in the past, so only care about actual activities
            criteria = activity => activity.Time === currentDayLabel && !activity.IsPlanned;
        }
        else {
            // This is in the future, so only care about planned activities
            criteria = activity => activity.Time === currentDayLabel && activity.IsPlanned;
        }

        let todayEntry = activities.find(criteria);

        

        // By default, today will contain the planned activity
        // If there is an actual activity, then replace it
        if (todayIndex === preDays) {
            let todayActual = activities.find(activity => activity.Time === currentDayLabel && !activity.IsPlanned);
            if (todayActual && (todayActual.CompetitiveValue > 0 || todayActual.PracticeValue > 0)) {
                todayEntry = todayActual;
            }
        }

        dayCounter = new Date(currentDayLabel).getDay();

        if (roundDownWeek) {
            for (let i = 0; i < dayCounter; i++) {
                calendar[weekCounter][i] = null;
            }
            roundDownWeek = false;
        }

        if (todayEntry) {
            //upcomingPlanned.push(todayEntry);
            calendar[weekCounter][dayCounter] = todayEntry;

            if (currentPlannerDay && todayEntry.Time === currentPlannerDay.Time) {
                plannerDay = todayEntry;
            }
        }
        else {
            //upcomingPlanned.push({ Time: currentDayLabel, PracticeValue: 0, CompetitiveValue: 0, IsPlanned: todayIndex > (preDays + 1) });
            calendar[weekCounter][dayCounter] = { Time: currentDayLabel, PracticeValue: 0, CompetitiveValue: 0, IsPlanned: todayIndex > (preDays) };
        }

        if (dayCounter === 6) {
            weekCounter++;
            calendar[weekCounter] = [];
            dayCounter = 0;
        }
        else {
            dayCounter++;
        }

        todayIndex++;
    }

    const valueEntered = (value, day, type) => {
        props.updatePlanned([{ value, time: day.Time, type }]);
    }

    var editingBox = <ClickOutside onClick={() => { setEditVisible(false); }}>
        <div style={{ zIndex: "1", position: 'fixed', top: editProperties.top - 30, left: editProperties.left - 45 }}>
            <div style={{ border: '1px solid #CCCCCC', background: '#333', filter: 'drop-shadow(0 0 10px #666666)', borderRadius: '10px', padding: '3px' }}>
                <div><strong>{plannerDay?.Time}</strong></div>
                <div className="fs-10">Add planned activity</div>
                <input type="number" value={plannerDay?.PracticeValue} style={{ textAlign: "center", color: "#999999", fontWeight: "bold", margin: "10px", width: "50px", fontSize: "16px" }} onChange={(e) => valueEntered(e.target.value, plannerDay, "practice")} />
                <input type="number" value={plannerDay?.CompetitiveValue} style={{ textAlign: "center", color: "#999999", fontWeight: "bold", margin: "10px", width: "50px", fontSize: "16px" }} onChange={(e) => valueEntered(e.target.value, plannerDay, "competitive")} />
                <div style={{ marginTop: '-8px' }}>
                    <span className="fs-10" style={{ color: "#EEE" }}>Practice&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="fs-10" style={{ color: "#EEE" }}>Comp</span>
                </div>
            </div>
        </div>
    </ClickOutside>

    return (<>
        <div className="row">
            <div className="col-lg-12 col-12">
                <div className="ms-panel">
                    <div className="">
                        {editVisible && editingBox}
                        {(!props.isMobile && true) &&
                            <div className="ms-card card-info">
                                <div className="ms-card-body">
                                    {!startPlanning && <button className="btn btn-success" type="submit" onClick={() => setStartPlanning(true)}>Goal Planner</button>}
                                    <p>
                                        {startPlanning &&
                                            <form className="needs-validation clearfix" noValidate onSubmit={(e) => e.preventDefault()}>

                                                <h6>Goal planner</h6>
                                                <div className="form-row">
                                                    <div className="col-3">
                                                        <label htmlFor="starting-load">Current</label>
                                                        <div className="input-group">
                                                            <input type="number" className="form-control" id="starting-load" value={planStartingLoad} onChange={(e) => onPlanStartLoadChange(e.target.value)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 form-group">
                                                        <label htmlFor="target-load">Target</label>
                                                        <div className="input-group">
                                                            <input type="number" className="form-control" id="target-load" value={planTargetLoad} onChange={(e) => onPlanTargetLoadChange(e.target.value)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <label htmlFor="activityName">Date</label>
                                                        <div className="input-group">
                                                            <input type="date" className="form-control" required id="activity-date" value={planTargetDate} onChange={(e) => onPlanTargetDateChange(e.target.value)}></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">Practice days (weighting)</div>
                                                    <div className="col-12">
                                                        <ul className="ms-list d-flex">

                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Su
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-sun" value={planPracticeDaysSun} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Sun', 'practice')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Mo
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-mon" value={planPracticeDaysMon} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Mon', 'practice')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Tu
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-tue" value={planPracticeDaysTue} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Tue', 'practice')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    We
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-wed" value={planPracticeDaysWed} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Wed', 'practice')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Th
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-thu" value={planPracticeDaysThu} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Thu', 'practice')}></input>

                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Fr
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-practice-fri" value={planPracticeDaysFri} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Fri', 'practice')}></input>

                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Sa
                                                                    <input style={{ width: '30px', padding: '0' }} type="number" className="form-control" id="days-practice-sat" value={planPracticeDaysSat} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Sat', 'practice')}></input>

                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12">Competitive days (weighting)</div>
                                                    <div className="col-12">
                                                        <ul className="ms-list d-flex">

                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Su
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-sun" value={planCompDaysSun} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Sun', 'competitive')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Mo
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-mon" value={planCompDaysMon} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Mon', 'competitive')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Tu
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-tue" value={planCompDaysTue} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Tue', 'competitive')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    We
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-wed" value={planCompDaysWed} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Wed', 'competitive')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Th
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-thu" value={planCompDaysThu} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Thu', 'competitive')}></input>
                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Fr
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-fri" value={planCompDaysFri} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Fri', 'competitive')}></input>

                                                                </label>
                                                            </li>
                                                            <li className="ms-list-item pl-0">
                                                                <label className="ms-checkbox-wrap">
                                                                    Sa
                                                                    <input style={{ width: '35px', padding: '0' }} type="number" className="form-control" id="days-comp-sat" value={planCompDaysSat} onChange={(e) => onPlanDaysOfWeekChange(e.target.value, 'Sat', 'competitive')}></input>

                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </form>}
                                    </p>
                                </div>
                            </div>

                        }
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>S</th>
                                        <th>M</th>
                                        <th>T</th>
                                        <th>W</th>
                                        <th>T</th>
                                        <th>F</th>
                                        <th>S</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {calendar.map((week, w) => {
                                        return (<tr key={w}>
                                            <td style={{ padding: "0px", fontSize: "8px" }}>{week[0] ? new Date(week[0].Time).toLocaleDateString('en-us', { month: "short" }) : ""}</td>
                                            {week.map((day, d) => {
                                                return <PlannerCell key={`week-${w}-day-${d}`} day={day} currentDay={todayLabel} showEditable={showEditable} onAddActivity={props.onAddActivity} isMobile={props.isMobile} readOnly={readOnly} />
                                            })
                                            }
                                        </tr>);
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {(isDirty) &&

                <div className="col-12 pl-4 pl-4 pb-3">
                    <div class="alert alert-warning" role="alert">
                        <i class="flaticon-alert"></i> <strong>Warning!</strong> You have unsaved changes to your plan.
                    </div>

                    <form className="needs-validation clearfix" onSubmit={(e) => e.preventDefault()}>
                        <button className="btn btn-secondary mr-3" onClick={props.onClose}>Cancel</button>
                        <button className="btn btn-success" onClick={props.onSave} type="submit">Save</button>
                    </form>
                </div>}
        </div>
    </>)
}

export default Planner;

function PlannerCell(props) {
    const handlePastClick = (day) => {
        if (props.readOnly) return;
        if (day.IsPlanned){
            day.IsPlanned = false;
        }
        props.onAddActivity(day)
    }

    const handleFutureClick = (target, day) => {
        if (props.readOnly) return;
        props.isMobile ? props.onAddActivity(day) : props.showEditable(target, day);
    }

    if (!props.day) {
        return (<td style={{ padding: "0px", fontSize: "12px", verticalAlign: "inherit" }}>&nbsp;</td>)
    }

    let bgStyle = { backgroundColor: "#333333" };
    let total = props.day.CompetitiveValue + props.day.PracticeValue;
    let cell = total > 0 ?
        <div style={{ marginVertical: "5px" }} onClick={() => handlePastClick(props.day)}>
            <div className="fs-16" style={{ color: props.day.IsPlanned ? "#FFFFFF" : "#66cc66", fontWeight: "bold" }}>
                {total}</div>
            <div style={{ fontSize: "8px" }}>({props.day.PracticeValue} / {props.day.CompetitiveValue})</div>

        </div> :
        <div style={{ marginVertical: "5px" }} onClick={() => handlePastClick(props.day)}>
            <div className="fs-16" style={{ color: "#FFFFFF", fontWeight: "bold", padding: "5px 0 5px 10px", opacity: '0.3' }}>
                {!props.readOnly ? <i className="material-icons fs-16">add_circle</i> : <div>&nbsp;</div>}
            </div>
        </div>

    // If the date is in the future, then show a different color, plus adding opens the inline dialog
    if (props.day.Time > props.currentDay) {
        bgStyle = { backgroundColor: "#666666" }
        cell = total > 0 ? <div style={{ marginVertical: "5px" }} onClick={(e) => handleFutureClick(e.currentTarget, props.day)}>
            <div className="fs-16" style={{ color: "#FFFFFF", fontWeight: "bold" }}>{total}</div>
            <div style={{ fontSize: "8px" }}>({props.day.PracticeValue} / {props.day.CompetitiveValue})</div>
        </div> :
            <div style={{ marginVertical: "5px" }} onClick={(e) => handleFutureClick(e.currentTarget, props.day)}>
                <div className="fs-16" style={{ color: "#FFFFFF", fontWeight: "bold", padding: "5px 0 5px 10px", opacity: '0.3' }}>
                    {!props.readOnly ? <i className="material-icons fs-16">add_circle</i> : <div>&nbsp;</div>}
                </div>
            </div>
    }

    return (<td style={{ padding: "0px", fontSize: "12px", borderRight: "1px solid #EEEEEE", verticalAlign: "inherit", ...bgStyle }}>
        <div style={{ padding: "0px", color: "#999999", textAlign: "right", paddingRight: "4px", marginBottom: "-14px", fontSize: "10px" }}>{new Date(props.day.Time).toLocaleDateString('en-us', { day: "numeric" })}</div>
        <div>{cell}</div>
    </td>);
}